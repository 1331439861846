import {getBasePath, mergeObjects} from './commonUtil';

export function localEnvironment(countryCode) {
  let baseConfig = {
    exrEnv: 'dev',
    exrServiceApi: 'http://localhost:8080/',
    adobeSrc: 'https://assets.adobedtm.com/248d63c2ff6b/01cce4ecbac0/launch-EN3068e51f4ce14dacac576c5214f9e027-development.min.js',
    mouseflowKey: '9fdf02dc-241d-4f2d-833b-2216e458dddf',

    // OPTIMIZELY VALUES
    optimizelyKey: 'SCzEuGCuiJyjsj3BPPraW',
    experimentVariation: 'exp_id_-_93',

    // TIMEOUT VALUES
    warningTimeMilliseconds: '600000000',
    sessionEndTimeMilliseconds: '1200000000',

    // PREPOPULATE FORM FIELDS
    prepopulateApprovedCustomerTestDataUSA: true,
    prepopulateCreditFreezeCustomerTestDataUSA: false,
    prepopulateApprovedCustomerTestDataCAN: true,
    prepopulateCreditFreezeCustomerTestDataCAN: false, // Test data only works Mon-Wed
    prepopulateQuebecCreditFreezeCustomerTestDataCAN: false,

    // WEB COMPONENT VALUES
    appModalURL: 'http://localhost:3002/webcompbutton/prequal-app-modal.js',
    minifiedCSSURL: 'http://localhost:3002/webcompbutton/prequal-app.css',

    // TARGET URLS
    specialEventTarget: 'https://www.buyfordnow.com/salesevent',
    exploreVehiclesTarget: 'https://shop.ford.com/showroom/',
    shopTarget: 'https://shop.ford.com/showroom/',
    shopIncentivesTarget: 'https://shop.ford.com/showroom/?linktype=incentives#/',
    ocaTarget: 'https://wwwqa.ford.ca/finance/apply/',

    // FLAGS
    specialEventFlag: true,
    offersAndIncentivesFlag: true,
    useEnterpriseReCaptcha: false,

    // AEM VALUES
    AEM_PUBLISHER_URL: 'https://wwwdev.creditbrandlb.ford.com',
    AEM_AUTHOR_URL: 'https://wwwdev.creditbrandauthorlb.ford.com',
    basePath: getBasePath('/finance/prequalify', '/finance/prequalify', 'https://wwwdev.creditbrandauthorlb.ford.com')
  };

  baseConfig.countryCode = countryCode;
  const targetConfigUSA = {
    AEM_PAGE_PATH: '/content/ucl/prequal/ford/en-us/tiles/landingpage',
    prequalifyTarget: 'http://localhost:3000/finance/prequalify'
  };
  const targetConfigCanada = {
    AEM_PAGE_PATH: '/content/ucl/prequal/ford/en-ca/tiles/landingpage',
    prequalifyTarget: 'http://localhost:3005/finance/prequalify'
  };

  baseConfig = mergeObjects(baseConfig, targetConfigUSA, targetConfigCanada, countryCode);
  return baseConfig;
}
